import React from "react";

const BcxSectionF = () => {
  return (
    <section id="sectionF">
      <div className="container">
        <div className="BcxSectionF">
          <div className="conteudo">
            <div className="title">
              <h2>
                <span>Documentação</span>
                <br />
                necessária
              </h2>
            </div>

            <div className="texto">
              <p>
                • RG, CPF e comprovante de endereço e renda dos sócios. Pessoas
                casadas ou com união estável devem apresentar também documentos
                do íntimo. As cópias devem ser coloridas.
              </p>

              <div className="inscMunic">
                <p>• Certidão Negativa de Tributos Municipais</p>
                <br />
                <a
                  href={
                    "https://doisirmaos.atende.net/autoatendimento/servicos/certidao-negativa-de-debitos/detalhar/1"
                  }
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Clique aqui e acesse
                </a>
              </div>
              <br />
              <p>
                <span>Mais documentos abaixo, conforme tipo de empresa:</span>
              </p>
              <p className="tipoEmpresa">
                <span>• Se empresa enquadrada como MEI:</span>
                <ul>
                  <li>
                    - CCMEI: Certificado de Condição de Microempreendedor
                    Individual.
                  </li>
                  <li>- DASN SIMEI: Declaração Anual do SIMEI.</li>
                </ul>
              </p>
              <p className="tipoEmpresa">
                <span>• Se empresa enquadrada como ME / EPP:</span>
                <ul>
                  <li>- Declaração de Firma Individual ou Contrato Social</li>
                  <li>
                    - Empresa do Simples Nacional: <br />
                    PGDAS-D. Programa Gerador do Documento de Arrecadação do
                    Simples Nacional Declaratório 2022, com faturamento dos
                    últimos 12 meses.
                  </li>

                  <li>
                    - Empresa do Lucro Presumido: <br />
                    SPED Contábil | Recibo, Balanço e DRE
                  </li>
                </ul>
              </p>
              <div className="asterisco">
                <p>
                  * A documentação completa será solicitada somente após análise
                  das informações fornecidas no formulário. Preencha com seus
                  dados e aguarde contato do Sicredi.
                </p>
                <p>
                  * Se você não é associado(a) da Sicredi Pioneira, será
                  necessária a abertura de uma conta corrente.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionF;
