import React from "react";
import Pessoa1 from "../assets/imgs/pessoa-1.png";
import Pessoa2 from "../assets/imgs/pessoa-2.png";
import Pessoa3 from "../assets/imgs/pessoa-3.png";
import Aviso from "../assets/imgs/ico-8.png";

const BcxSectionE = () => {
  return (
    <section id="sectionE">
      <div className="container">
        <div className="BcxSectionE">
          <h2 className="title">
            Quem pode ter acesso
            <br /> <span>ao Credi2Irmãos</span>
          </h2>

          <div className="micInd rowCard">
            <div className="imagem">
              <img src={Pessoa1} alt="Entrada da cidade de Dois Irmãos" />
            </div>

            <div className="texto">
              <h3>
                <span>Microempreendedor</span>
                <br />
                Individual
              </h3>
              <p>
                Com receita bruta anual igual <br /> ou inferior a R$ 81 mil
              </p>
              {/* <p>
                <span>Limite de crédito:</span>
                <br />
                R$ 10 mil em 36 parcelas (sem carência) de até R$ 361,22
                <br />
                R$ 10 mil em 34 parcelas (02 meses de carência) de até R$ 381,11
                <br />
                *A taxa da garantia RSGaranti é de até
                <br />
                R$ 662,40
              </p> */}
              <p></p>
            </div>
          </div>

          <div className="micEmp rowCard">
            <div className="imagem">
              <img src={Pessoa2} alt="Escadaria Parque Romeo Benicio Wolf" />
            </div>

            <div className="texto">
              <h3>Microempresa</h3>
              <p>
                Com receita bruta anual igual <br /> ou inferior a R$ 360 mil
              </p>
              {/* <p>
                <span>Limite de crédito:</span>
                <br />
                <br />
                R$ 60 mil em 36 parcelas (sem carência) de até R$ 2.201,79
                <br />
                R$ 60 mil em 34 parcelas (02 meses de carência) de até R$
                2.334,68
                <br />
                *A taxa da garantia RSGaranti é de até <br />
                R$ 3.974,40
              </p> */}
            </div>
          </div>

          <div className="peqEmp rowCard">
            <div className="imagem">
              <img
                src={Pessoa3}
                alt="Prédio centenário da antiga Igreja Matriz de São Miguel."
              />
            </div>

            <div className="texto">
              <h3>
                Pequenas
                <br />
                <span>Empresas</span>
              </h3>
              <p>
                Com receita bruta anual igual <br /> ou inferior a R$ 4,8
                milhões
              </p>
              {/* <p>
                <span>Limite de crédito:</span>
                <br />
                <br />
                R$ 120 mil em 36 parcelas (sem carência) de até R$ 4.403,58
                <br />
                R$ 120 mil em 34 parcelas (02 meses de carência) de até R$
                4.669,35
                <br />
                * A taxa da garantia RSGaranti é de até
                <br />
                R$ 7.948,80
              </p> */}
            </div>
          </div>

          <div className="aviso">
            <div className="imgAviso">
              <img
                src={Aviso}
                alt="Icone, formato Balão, com Exclamação dentro"
              />
            </div>

            <div className="textoAviso">
              <p>
                • Crédito sujeito a análise de crédito. Parcelas SIMULADAS com
                valores APROXIMADOS sem IOF.
                <br />• Terão acesso à linha associados que apresentarem no
                mínimo 01 ano de faturamento completo, declarados nos documentos
                indicados na documentação necessária abaixo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionE;
