let agencias = [
  {
    id: 468,
    name: 'Dois Irmãos',
    cooperativa: 5,
  },
  {
    id: 4470,
    name: 'São Miguel',
    cooperativa: 5,
  },
];

export { agencias };
